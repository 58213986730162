import { Link } from '@remix-run/react';
import { useEffectOnce } from 'react-use';
import { $path } from 'remix-routes';

import {
  type DtoGamePack,
  type DtoProduct,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import { fromDTOGamePack } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { formatCurrency } from '../../utils/currency';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { GamePackUtils } from '../Game/GamePack/utils';
import { GamePackCoverImage } from '../Game/GamePackCoverPres';
import { CloseIcon } from '../icons/CloseIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { useMyOrganization } from '../Organization';
import { ProductUtils } from '../Product/utils';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';

function SpotifySocialProof() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const analytics = useOneTimePurchaseAnalytics();

  const handleViewVideo = () => {
    analytics.trackEventPurchaseTestimonialVideoViewed();

    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-3/4 max-w-[#1280]'
          innerClassName='rounded-xl'
          borderStyle='gray'
          onClose={p.internalOnConfirm}
        >
          <div className='relative w-full group'>
            <div className='absolute z-10 right-2.5 top-2.5 group-hover:opacity-100 opacity-0'>
              <button
                type='button'
                className='w-7 h-7 flex justify-center items-center rounded-full bg-black bg-opacity-50 hover:bg-opacity-75'
                onClick={p.internalOnConfirm}
              >
                <CloseIcon className='w-4 h-4 fill-current text-white' />
              </button>
            </div>

            <video
              src={getStaticAssetPath(
                'videos/otp/choose-purchase-option-testimonial.mp4'
              )}
              className='w-full rounded-xl'
              controls
              autoPlay
            />
          </div>
        </ModalWrapper>
      ),
    });
  };

  return (
    <div className='w-60 bg-white text-black rounded-2.5xl p-5 flex flex-col gap-3'>
      <div className='flex items-center gap-2'>
        <img
          src={getStaticAssetPath('images/onboarding/spotify.png')}
          alt=''
          className='w-19 h-5.5 object-contain'
        />
        <div className='text-icon-gray text-3xs font-medium'>Case Study</div>
      </div>
      <div className='w-full text-sms'>
        Spotify transformed how they collaborate and increased their engagement
        by 950% after switching from buying single events to giving their team
        unlimited access.
      </div>
      <button
        type='button'
        className='text-sms font-bold text-[#383838] flex items-center gap-1'
        onClick={handleViewVideo}
      >
        <PlayIcon className='w-3.5 h-3.5 fill-current text-[#383838]' />
        Watch Testimonial Video
      </button>
    </div>
  );
}

function PurchaseOptionSubscription(props: {
  pack: DtoGamePack;
  product: DtoProduct;
  headcount: number;
}) {
  const { pack, product, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();
  const organization = useMyOrganization();
  const isLive = GamePackUtils.IsLive(pack);
  const prices = ProductUtils.ActivePricesBySmallestBillingInterval(product);
  const price = ProductUtils.FindPrice(prices, headcount);

  if (!price) return null;

  const search = new URLSearchParams(window.location.search);
  search.set('product-id', product.id);
  search.set('price-id', price.id);
  search.set('headcount', headcount.toString());
  const trial =
    organization?.canTrial && product.trialPeriodDays && headcount <= 10;
  if (trial) {
    search.set('trial', 'true');
  }

  return (
    <div className='relative'>
      <div className='absolute left-0 -top-3'>
        <div
          className='w-32 h-7.5 rounded-md font-bold text-white flex justify-center items-center'
          style={{
            background: 'linear-gradient(244deg, #0029FF 0.84%, #FE0653 100%)',
          }}
        >
          Best Value
        </div>
      </div>

      <div
        className='
          w-142 bg-white text-black rounded-2.5xl 
          flex flex-col justify-between gap-5
        '
      >
        <div className='w-full flex'>
          <div className='flex-1'>
            <div className='p-7.5 pb-2 border-b border-black border-opacity-10'>
              <p className='text-xl font-bold text-red-006'>
                {isLive
                  ? 'Unlimited Live Event Access'
                  : 'Unlimited Event Access'}
              </p>
              <p className='mt-2.5 text-xl font-bold'>
                {formatCurrency(price.amount)}/
                {ProductUtils.FormatInterval(price.billingInterval)}
              </p>
              <p className='text-sms text-[#2025E9]'>
                No risk - Cancel anytime
              </p>
            </div>

            <ul className='pl-7.5 pt-5 text-sms flex flex-col gap-2.5'>
              <li className='flex gap-2.5'>
                <p className='font-bold text-green-001'>✓</p>
                <div>
                  <p>{isLive ? 'Live Hosted Experience' : 'Access to'}</p>
                  <div className='flex items-center gap-1.5'>
                    <p className='text-sms font-bold'>{pack.name}</p>
                    <GamePackCoverImage
                      pack={fromDTOGamePack(pack)}
                      preferredFormatVersion={MediaFormatVersion.SM}
                      alt={''}
                      className='flex-none w-13.5 h-7.5 object-cover rounded'
                    />
                  </div>
                </div>
              </li>
              <li className='flex gap-2.5'>
                <p className='font-bold text-green-001'>✓</p>
                <p>
                  {isLive
                    ? 'Unlimited access to Live Events plus 100s of on demand icebreakers, escape rooms, and more...'
                    : 'Unlimited access to 100s of icebreakers, escape rooms, and more...'}
                </p>
              </li>
              <li className='flex gap-2.5'>
                <p className='font-bold text-green-001'>✓</p>
                <p>Dedicated event planner</p>
              </li>
            </ul>
          </div>

          <img
            src={getStaticAssetPath(
              'images/otp/choose-purchase-option-games.png'
            )}
            alt=''
            className='flex-none w-66 h-68 object-cover rounded-tr-2.5xl'
          ></img>
        </div>

        <div className='mb-3 flex flex-col items-center gap-1.5'>
          <Link
            to={{
              pathname: $path('/onboarding/billing'),
              search: search.toString(),
            }}
            onClick={() =>
              analytics.trackEventPurchaseOptionChosen({
                type: 'subscription',
              })
            }
            className='btn bg-green-001 hover:bg-[#33c35b] transition-colors text-white font-bold w-86 h-12.5 flex justify-center items-center'
          >
            {trial
              ? `Start ${product.trialPeriodDays} Day FREE Trial 🤩`
              : `Get Started Today 🤩`}
          </Link>
          <p className=' text-3xs text-icon-gray'>
            Spotify & Zoom picked this!
          </p>
        </div>
      </div>
    </div>
  );
}

function PurchaseOptionSingleEvent(props: {
  pack: DtoGamePack;
  headcount: number;
}) {
  const { pack, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();
  const isLive = GamePackUtils.IsLive(pack);
  const price = GamePackUtils.FindOneTimePrice(pack, headcount);
  if (!price) return null;

  return (
    <div
      className='
        w-80 h-full bg-modal rounded-2.5xl border border-secondary
        flex flex-col
      '
    >
      <div className='w-full p-7.5 pb-2 border-b border-secondary'>
        <p className='text-xl font-bold'>Single Event Access</p>
        <p className='mt-2.5 text-xl font-bold'>
          {formatCurrency(price.amount, false)}
        </p>
        <p className='text-sms text-icon-gray'>One-time purchase</p>
      </div>

      <div className='w-full flex-1 p-7.5 pt-5 flex flex-col justify-between gap-2'>
        <ul className='text-sms flex flex-col gap-2.5'>
          <li className='flex gap-2.5'>
            <p className='font-bold'>✓</p>
            <div>
              <p>{isLive ? 'Live Hosted Experience' : 'Access to'}</p>
              <div className='flex items-center gap-1.5'>
                <p className='text-sms font-bold'>{pack.name}</p>
                <GamePackCoverImage
                  pack={fromDTOGamePack(pack)}
                  preferredFormatVersion={MediaFormatVersion.SM}
                  alt={''}
                  className='flex-none w-13.5 h-7.5 object-cover rounded'
                />
              </div>
            </div>
          </li>
          {!isLive && (
            <li className='flex items-center gap-2.5'>
              <p className='font-bold'>✓</p>
              <p>Shareable memory image</p>
            </li>
          )}
          <li className='flex items-center gap-2.5'>
            <p className='font-bold'>✓</p>
            <p>Live chat support</p>
          </li>
        </ul>

        {isLive ? (
          <Link
            to={$path('/checkout/schedule-pre-call', window.location.search)}
            onClick={() =>
              analytics.trackEventPurchaseOptionChosen({ type: 'single-event' })
            }
            className='btn-secondary w-full h-12.5 flex justify-center items-center'
          >
            Schedule a Planning Session
          </Link>
        ) : (
          <Link
            to={$path('/checkout/pay', window.location.search)}
            onClick={() =>
              analytics.trackEventPurchaseOptionChosen({ type: 'single-event' })
            }
            className='btn-secondary w-full h-12.5 flex justify-center items-center'
          >
            Purchase Single Event
          </Link>
        )}
      </div>
    </div>
  );
}

export function CheckoutChoosePurchaseOption(props: {
  pack: DtoGamePack;
  product: DtoProduct;
  headcount: number;
}) {
  const { pack, product, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();

  useEffectOnce(() => {
    analytics.trackEventPurchasePurchaseOptionViewed();
  });

  return (
    <OTPCheckoutLayout
      pack={pack}
      progress={20}
      checkAccessible
      background={false}
      containerClassName='!w-full h-full !items-start'
    >
      <div className='flex flex-col items-center'>
        <div className='text-2xl font-medium'>Your Quote Is Ready! 🎊</div>
        <div className='mt-10 relative'>
          <div className='absolute top-0 bottom-0 right-full mr-2.5'>
            <PurchaseOptionSingleEvent pack={pack} headcount={headcount} />
          </div>

          <PurchaseOptionSubscription
            pack={pack}
            product={product}
            headcount={headcount}
          />

          <div className='absolute top-0 left-full ml-2.5'>
            <SpotifySocialProof />
          </div>
        </div>

        <div className='mt-7.5 w-140 flex flex-col gap-2'>
          <div className='text-sms text-icon-gray italic text-center'>
            Trusted by 90,000+ customers in 100+ countries
          </div>
          <div className='flex items-center justify-between'>
            <img
              src={getStaticAssetPath('images/onboarding/microsoft-v2.png')}
              alt='microsoft'
              className='w-30'
            />
            <img
              src={getStaticAssetPath('images/onboarding/stripe-v2.png')}
              alt='stripe'
              className='w-21'
            />
            <img
              src={getStaticAssetPath('images/onboarding/spotify-v2.png')}
              alt='spotify'
              className='w-25'
            />
            <img
              src={getStaticAssetPath('images/onboarding/meta-v2.png')}
              alt='meta'
              className='w-25'
            />
            <img
              src={getStaticAssetPath('images/onboarding/netflix-v3.png')}
              alt='netflix'
              className='w-22'
            />
          </div>
        </div>
      </div>
    </OTPCheckoutLayout>
  );
}
